<script lang="ts" setup>
window.addEventListener("afterprint", () => self.close);
</script>

<template>
  <router-view></router-view>
</template>

<style lang="scss">
@page {
  size: letter portrait;
  margin-top: 0in;
  margin-bottom: 0in;
  margin-right: 0in;
  margin-left: 0in;
}
</style>
